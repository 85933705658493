<template>
  <div>
      <el-row>
        <el-col :span="24">
          <div  class="flex-container flex-wrap-w">
            <div class="imgBox" @mouseenter="item.maskShow = true" @mouseleave="item.maskShow = false"
                 v-for="(item,index) in jsonList" :key="index" style="margin:0 12px 12px 0;">
              <el-image
                ref="image"
                style="width: 170px; height: 170px"
                :src="item.imgUrl"
                :preview-src-list="[item.imgUrl]">
              </el-image>
              <div class="maskBox" v-if="item.maskShow">
                <i class="el-icon-view" @click="viewImg(index)"></i>
                <i class="el-icon-delete" @click="deleteImg(index)"
                   v-if="!doned && ['edit', 'add','sign'].includes(securityType) && businessStatus!='APPROVING'"></i>
              </div>
            </div>
            <div class="cardBorder" @click="openSignature('receive')"
                 v-if="!doned && canAddSignature && ['edit', 'add','sign'].includes(securityType) && businessStatus!='APPROVING'">
              <i class="el-icon-plus"></i>
            </div>
          </div>
          <!--          </el-form-item>-->
        </el-col>
      </el-row>
    <!--签名弹窗-->
    <el-dialog
      title="电子签名"
      :visible.sync="signatureShow"
      v-if="signatureShow"
      width="1000px">
      <signature @getSignatureImg="getSignatureImg"></signature>
    </el-dialog>
    <el-dialog
      title="预览签名"
      :visible.sync="imgShow"
      width="1000px">
      <img :src="imgSrc" style="width: 100%;height: 500px;">
    </el-dialog>
  </div>
</template>
<script>
import signature from "@/views/components/signature";

export default {
  props: {
    securityType: {
      type: String,
      default: "view",
    },
    list: {
      type: Array,
      default: [],
    },
    doned:{
      type: Boolean,
      default: false
    },
    businessStatus: {
      type: String,
      default: "view",
    },
  },
  watch:{
    list:{
      handler(newVal, oldVal) {
        this.jsonList=newVal.filter(item=>item.imgUrl).map(e => {
          return  {
            imgUrl: e.imgUrl,
            id: e.id,
            maskShow: false
          }
        })
        if(this.jsonList.length !=0 ){
          this.canAddSignature=false
        }
      }
    },
  },
  data() {
    return {
      jsonList:[],
      canAddSignature: true,
      signatureShow: false,
      signatureType: '',
      imgSrc: '',
      maskShow: false
    };
  },
  components: {
    signature
  },
  mounted() {

  },
  methods: {
    viewImg(index){
      this.$refs.image[index].clickHandler()
    },
    getSignatureImg(imgUrl) {
      if (this.canAddSignature) {
        // 如果可以添加签名
        this.$emit('getSecuritySignature', imgUrl)
        this.jsonList.push({
          imgUrl: imgUrl,
          maskShow: false
        });
        this.canAddSignature = false; // 签名后设置为false，禁止再次添加签名
        this.signatureShow = false;
      }
    },
    openSignature(type) {
      this.signatureShow = true;
      this.signatureType = type;
    },
    deleteImg( index) {
      this.$emit('deleteImg')
      this.canAddSignature = true;
      this.jsonList.splice(index, 1)
      this.$message.success('操作成功');
    },
  },
};
</script>

<style scoped lang='scss'>
.cardBorder {
  width: 170px;
  height: 170px;
  border: 1px dashed #DDD;
  border-radius: 10px;
  cursor: pointer;
  line-height: 170px;
  text-align: center;
  color: #8c939d;
  font-size: 28px;
}

.cardBorder:hover {
  border: 1px dashed #2979FF;
}

.imgBox {
  width: 170px;
  height: 170px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.maskBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 22px;
}

.maskBox i {
  line-height: 170px;
  width: 30px;
}

.maskBox i:hover {
  color: #2979FF;
}

.imgBox img {
  width: 100%;
  height: 100%;
}
</style>
